// NOT BEING USED IN BOOK MERLIN

import React from "react";
import Helmet from "react-helmet";

import classNames from "classnames";

import withStyles from "@material-ui/core/styles/withStyles";

import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import HeaderLinks from "components/Header/HeaderLinks.jsx";

import WorkSection from "../LandingPage/Sections/WorkSection.jsx";

import componentsStyle from "assets/jss/material-kit-react/views/components.jsx";

class Components extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
      <Helmet>
        <title>BookMerlin</title>
      </Helmet>
        <Header
          color="transparent"
          brand="Book Merlin"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 50,
            color: "#009688"
          }}
          {...rest}
        />
        <Parallax image={require("assets/img/books.jpg")}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem>
                <div className={classNames(classes.main, classes.mainRaised)}>
                  <div className={classes.container}>
                    <WorkSection />
                  </div>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>

        <Footer />
      </div>
    );
  }
}

export default withStyles(componentsStyle)(Components);
